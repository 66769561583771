<template>
    <div class=" flex flex-wrap justify-center mt-3">
        <h1 class="text-3xl font-black uppercase">  
            Joshua Manaol
        </h1>
    </div>
    <div class="flex flex-wrap justify-center mt-1">
        <p class="md:text-md sm:text-sm font-medium">I'm a DevOps / SRE dude that keeps the automation simple and 
            effective through containers, CI / CD, infrastructure as code (IaC) and scripts.</p>
    </div>
    <div class="flex flex-wrap justify-center mt-3">
      <a href="https://ph.linkedin.com/in/joshuamanaol" target="_blank">
        <img alt="linkedin" src="../assets/linkedin.png" class="w-7 h-auto">
      </a>
    </div>
</template>

<script setup>

</script>
