<template>
   <div class="flex flex-wrap justify-center">
    <img src="../assets/logo.png" alt="logo" class="max-w-md h-auto">
   </div>

 
</template>

<script setup>

</script>
